import React from 'react';
import { Button } from '../../../../fc-components/main/components';
import { slugify } from '../../../../../utils/string_util';
import { Arrow } from '../../../../fc-components/main/components';

export const SplashPage = ({
  className = '',
  arrow = false,
  franchiseName = 'default',
  previousYearText,
  previousYearUrl,
  applicationText,
  applicationUrl,
  splashHeadline,
  splashMessage,
  logoSrc,
  colorObj
}) => {
  const {
    backgroundColor = '#fff', 
    buttonBackground = '#000', 
    buttonBackgroundHover = '#fff', 
    labelColor = '#fff',
    labelColorHover = '#000',
    linkColor = '#000'
   } = colorObj;

   return ( 
    <div
      className={`splashPageContainer ${slugify(franchiseName)} ${className && className}`}
      style={{
        backgroundColor: backgroundColor,
      }}
    >
      {logoSrc && (
        <div className="imageContainer">
          <img src={logoSrc} alt="" />
        </div>
      )}
      <div className="contentContainer">
        <div className="headline">{splashHeadline || 'Coming Soon'}</div>
        <div className="message">{splashMessage || ''}</div>
        {previousYearUrl && (
          <a className="previousYear" href={previousYearUrl}>
            <Button
              rounded
              bgColor={buttonBackground}
              hoverColor={buttonBackgroundHover}
              labelHoverColor={labelColorHover}
              labelColor={labelColor}
              label={previousYearText || 'See last year\'s list'}
            />
          </a>
        )}
        {applicationUrl && (
          <a
            className="previousLink"
            href={applicationUrl}
            style={{
              color: linkColor,
            }}
          >
            {applicationText || `Apply for next year's list`}
          </a>
        )}
      </div>
    </div>
  );
}