import React from 'react';
import PropTypes from 'prop-types';

const SimpleList = ({ items = [], interstitial }) => {
  const itemsBetween = 10; // Change this to adjust the number of items between the inserted item
  const insertInterstitials = (itemArray, interstitialUnit) => {
    if (!interstitialUnit) return itemArray;

    const modifiedData = itemArray.reduce((acc, item, index) => {
      acc.push(item);
      if ((index + 1) % itemsBetween === 0 && index !== itemArray.length - 1) {
        acc.push(
          <div className="simpleList__item--interstitial">
            {interstitialUnit}
          </div>
        );
      }
      return acc;
    }, []);
    return modifiedData;
  };

  const buildListItems = listItems =>
    listItems.map((item, index) => (
      <a key={index} className="simpleList__item" href={item.url}>
        <div className="simpleList__item__body">
          <div className="simpleList__item--title">
            {item.title || item.company}
          </div>
          <div className="simpleList__item--description">
            {item.description}
          </div>
        </div>
        <div className="simpleList__item__right">
          <div className="simpleList__item--icon">{item.icon || '→'}</div>
        </div>
      </a>
    ));

  const itemElements = buildListItems(items);
  const modifiedElements = insertInterstitials(itemElements, interstitial);

  return (
    <div className="simpleList__container">
      <div className="simpleList__container__inner">{modifiedElements}</div>
    </div>
  );
};

SimpleList.defaultProps = {
  interstitial: null
};

SimpleList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  interstitial: PropTypes.element
};

export default SimpleList;
